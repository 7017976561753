<template>
  <b-modal
    visible
    centered
    title="Закрытие обращения чата"
    scrollable
    size="lg"
    :header-class="'p-2'"
    :hide-footer="false"
    no-close-on-backdrop
    @hidden="onCancel"
    @ok="onCancel"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>
    <template v-else>
      <div class="crm-container">
        <div class="content">
          <div class="content-info">
            <div class="d-flex">
              <span class="crm-label">
                Цель обращения:
              </span>
              <span class="crm-label-value ml-2">
                Информационное
              </span>
            </div>

            <div class="d-flex ml-4">
              <span class="crm-label">
                Категория обращения:
              </span>
              <span class="crm-label-value ml-2">
                Общая информация
              </span>
            </div>
          </div>
          <b-form-group
            label="Комментарий к обращению *"
            invalid-feedback="Обязательное поле"
            :state="$v.$anyError ? !$v.comment.$error : null"
            class="text-error mt-3"
          >
            <b-form-textarea
              v-model.trim="$v.comment.$model"
              placeholder="Введите комментарий"
              rows="2"
              max-rows="8"
            />
          </b-form-group>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <b-button
        variant="outline-primary"
        :type="$const.PRIMARY_BUTTON"
        class="sm"
        @click="onCancel"
      >
        Отменить
      </b-button>

      <b-button
        variant="outline-success"
        :type="$const.PRIMARY_BUTTON"
        class="sm"
        @click="createAppeal"
      >
        Закрыть обращение
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Preloader from '@/components/Preloader';
import { required } from 'vuelidate/lib/validators';

const APPEALS_STATUSES_CLOSED = 2;
const APPEALS_PURPOSES_INFORMATIONAL = 0;
const APPEALS_CATEGORIES_INFORTATION_COMMON = 1;

export default {
  name: 'CreatingAppealBeforeClosingModal',
  components: {
    Preloader,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    patient: {
      type: Object,
      default: () => ({}),
    },
    afterDelete: {
      type: [Function],
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      isSaving: false,
      isOpen: true,
      comment: '',
    };
  },
  validations() {
    return {
      comment: { required },
    };
  },
  methods: {
    onCancel() {
      this.$emit('input', false);
    },
    onClose() {
      this.$emit('input', false);
    },
    async createAppeal() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.isSaving = true;
      try {
        const data = {
          ...this.patient,
          categoryId: APPEALS_CATEGORIES_INFORTATION_COMMON,
          status: APPEALS_STATUSES_CLOSED,
          comment: this.comment,
          chatAppealId: this.patient.id,
          newPurpose: APPEALS_PURPOSES_INFORMATIONAL,
        };
        this.$emit('afterDelete', this.patient.id, data);
        this.onClose();
      } catch (e) {
        console.error(e);
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 1px;
  max-height: 50vh;
  .content {
    width: 100%;
    &-info {
      display: flex;
      .crm-label {
        margin-bottom: 5px;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #707070;
        &-value {
          font-size: 14px;
          line-height: 17px;
          color: #615c5c;
        }
      }
    }
    .text-error{
      font-size: 14px;
    }
  }
}
</style>
